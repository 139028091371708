import React from "react"

import Layout from "../components/layout"
import CutContainer from "../components/cutContainer"
import { purpleGradient } from "../services/constants"
import styles from "./presskit.module.scss"
import globalStyles from "../services/globalStyles.module.scss"
import Button from "../components/button"
import logo from "../images/logo.svg"
import logo2 from "../images/sideline_logo.svg"
import productImg from "../images/calendar.webp"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql } from "gatsby";

class PresskitPage extends React.Component {
  render() {
    const { texts: { _, _: { presskit, press_kit_title } } } = this.props.data;
    const languageCode = this.props.pageContext && this.props.pageContext.lang ? this.props.pageContext.lang : "en";

    return (
      <Layout header={<Header num={1} translations={_} headerFullYOffset={500} fixed mobileHeaderFullYOffset={40} whiteFont languageCode={languageCode} />} translations={_} languageCode={languageCode}>
        <CutContainer
          style={{
            background: purpleGradient,
            position: "relative",
          }}
          sizeOfCut={2}
          styleClass={styles.cutContainer}
        >
          <h1 className={styles.header}> {press_kit_title} </h1>
          <Button classStyle={styles.purpleButton}>{presskit.download_package.toUpperCase()}</Button>
        </CutContainer>
        <div className={[globalStyles.contentContainer220, styles.content].join(' ')}>
          <h1>{presskit.whats_included}</h1>
          <div className={styles.card}>
            <h2>{presskit.logos}</h2>
            <div className={styles.formatContainer}>
              <span className={styles.format}>EPS</span>
              <span className={styles.format}>PNG</span>
              <span className={styles.format}>SVG</span>
              <span className={styles.format}>PSD</span>
            </div>
            <div className={styles.logoContainer}>
              <div>
                <img src={logo} alt="xps" />
              </div>
              <div>
                <img src={logo2} alt="sidelinesports" />
              </div>
            </div>
            <Button classStyle={styles.button}>{presskit.download_logos.toUpperCase()}</Button>
          </div>
          <div className={styles.card}>
            <h2>{presskit.high_res}</h2>
            <div className={styles.formatContainer}>
              <span className={styles.format}>PNG</span>
              <span className={styles.format}>PSD</span>
            </div>
            <div className={styles.productContainer}>
              <img src={productImg} alt="product" />
              <img src={productImg} alt="product" />
              <img src={productImg} alt="product" />
              <img src={productImg} alt="product" />
              <img src={productImg} alt="product" />
              <img src={productImg} alt="product" />
            </div>
            <Button classStyle={styles.button}>{presskit.download_images.toUpperCase()}</Button>
          </div>
          <div className={styles.card}>
            <h2>{presskit.guidelines}</h2>
            <div style={{marginBottom: 0}} className={styles.formatContainer}>
              <span className={styles.format}>PDF</span>
            </div>
            <Button classStyle={styles.button}>{presskit.download_guidelines.toUpperCase()}</Button>
          </div>
        </div>
        <Header num={2} translations={_} whiteFont headerFullYOffset={500} className={globalStyles.hideOnMobile} seeThrough languageCode={languageCode}/>
        <Footer translations={_}  languageCode={languageCode}/>
      </Layout>
    )
  }
}
export default PresskitPage

export const query = graphql`
  query Presskit($langFile: String) {
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: {eq: $langFile}) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        press_kit_title
        presskit {
          download_package
          whats_included
          logos
          download_logos
          high_res
          download_images
          guidelines
          download_guidelines
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
  }
`
